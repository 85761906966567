@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap');
*,
:before,
:after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
}

html {
    font-family: "Urbanist", sans-serif !important;
}

body {
    margin: 0;
    line-height: inherit;
    font-family: "Urbanist", sans-serif !important;
}


body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background-image: url(img/bg-background.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.6);
}


.progressbar-width {
    width: 50% !important;
}

hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
}

abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
}

h4 {
    font-size: inherit;
    font-weight: inherit;
}

a {
    color: inherit;
    text-decoration: inherit;
}

button,
input {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
}

button {
    text-transform: none;
}

button,
[type="button"] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
}

:-moz-focusring {
    outline: auto;
}

:-moz-ui-invalid {
    box-shadow: none;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}

::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

h4,
hr,
p {
    margin: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

input::-moz-placeholder {
    opacity: 1;
    color: #9ca3af;
}

button {
    cursor: pointer;
}

img,
svg {
    display: block;
}

img {
    max-width: 100%;
    height: auto;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 640p) {
    .container {
        max-width: 640p;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.top-1 {
    top: 0.25rem;
}

.z-10 {
    z-index: 10;
}

.-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.-mt-0\.5 {
    margin-top: -0.125rem;
}

.-mt-2 {
    margin-top: -0.5rem;
}

.-mt-6 {
    margin-top: -1.5rem;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.mb-4 {
    margin-bottom: 1rem;
}

.mb-6 {
    margin-bottom: 1.5rem;
}

.mt-1 {
    margin-top: 0.25rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-4 {
    margin-top: 1rem;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.d-flex {
    display: flex;
}

.hidden {
    display: none;
}

.h-0\.5 {
    height: 0.125rem;
}

.h-6 {
    height: 1.5rem;
}

.h-8 {
    height: 2rem;
}

.h-full {
    height: 100%;
}

.h-screen {
    min-height: 100vh;
}

.w-6 {
    width: 1.5rem;
}

.w-auto {
    width: auto;
}

.w-full {
    width: 100%;
}

.min-w-\[3\.5rem\] {
    min-width: 3.5rem;
}

.max-w-lg {
    max-width: 32rem;
}

.flex-1 {
    flex: 1 1 0%;
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.gap-1 {
    gap: 0.25rem;
}

.gap-16 {
    gap: 4rem;
}

.gap-2 {
    gap: 0.5rem;
}

.gap-3 {
    gap: 0.75rem;
}

.gap-4 {
    gap: 1rem;
}

.gap-6 {
    gap: 1.5rem;
}

.gap-8 {
    gap: 2rem;
}

.self-stretch {
    align-self: stretch;
}

.overflow-hidden {
    overflow: hidden;
}

.rounded-3xl {
    border-radius: 1.5rem;
}

.rounded-full {
    border-radius: 9999px;
}

.rounded-md {
    border-radius: 0.375rem;
}

.rounded-xl {
    border-radius: 0.75rem;
}

.rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
}

.border-2 {
    border-width: 2px;
}

.border-l-2 {
    border-left-width: 2px;
}

.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-secondary {
    --tw-border-opacity: 1;
    border-color: rgb(133 170 243 / var(--tw-border-opacity));
}

.bg-\[\#f4f4f6\] {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 246 / var(--tw-bg-opacity));
}

.bg-dark {
    --tw-bg-opacity: 1;
    background-color: rgb(188 81 20 / var(--tw-bg-opacity));
}

.bg-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(250 186 21 / var(--tw-bg-opacity));
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.object-contain {
    -o-object-fit: contain;
    object-fit: contain;
}

.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.text-center {
    text-align: center;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}

.font-bold {
    font-weight: 700;
}

.font-semibold {
    font-weight: 600;
}

.text-uppercase {
    text-transform: text-uppercase;
}

.leading-none {
    line-height: 1;
}

.leading-relaxed {
    line-height: 1.625;
}

.tracking-widest {
    letter-spacing: 0.1em;
}

.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-orange-600 {
    --tw-text-opacity: 1;
    color: rgb(234 88 12 / var(--tw-text-opacity));
}

.text-primary {
    --tw-text-opacity: 1;
    color: rgb(2 0 108 / var(--tw-text-opacity));
}

.text-primary-dark {
    --tw-text-opacity: 1;
    color: rgb(247 146 86 / var(--tw-text-opacity));
}

.text-secondary {
    --tw-text-opacity: 1;
    color: rgb(133 170 243 / var(--tw-text-opacity));
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-transparent {
    --tw-ring-color: transparent;
}

html {
    font-size: 16px;
    scroll-behavior: smooth;
}

.progress-bar3 {
    height: 18px;
    border-radius: 4px;
    background-image: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #7dc8e8, #5856d6, #ff2d55);
    transition: 0.4s linear;
    transition-property: width, background-color;
}

.progress-infinite .progress-bar3 {
    width: 100%;
    background-image: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #7dc8e8, #5856d6, #ff2d55);
    animation: colorAnimation 1s infinite;
}

.progress {
    padding: 6px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    box-shadow: inset 0 1px 2px #00000040, 0 1px #ffffff14;
}

@keyframes colorAnimation {
    0% {
        background-image: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #7dc8e8, #5856d6, #ff2d55);
    }

    20% {
        background-image: linear-gradient(to right, #5ac8fa, #007aff, #7dc8e8, #5856d6, #ff2d55, #4cd964);
    }

    40% {
        background-image: linear-gradient(to right, #007aff, #7dc8e8, #5856d6, #ff2d55, #4cd964, #5ac8fa);
    }

    60% {
        background-image: linear-gradient(to right, #7dc8e8, #5856d6, #ff2d55, #4cd964, #5ac8fa, #007aff);
    }

    to {
        background-image: linear-gradient(to right, #5856d6, #ff2d55, #4cd964, #5ac8fa, #007aff, #7dc8e8);
    }
}

.focus-within\:border-primary\/50:focus-within {
    border-color: #0e08ff80;
}

.focus-within\:ring-primary\/20:focus-within {
    --tw-ring-color: rgb(2 0 108 / 0.2);
}

.hover\:text-primary:hover {
    --tw-text-opacity: 1;
    color: rgb(2 0 108 / var(--tw-text-opacity));
}

.hover\:opacity-75:hover {
    opacity: 0.75;
}

.disabled\:cursor-not-allowed:disabled {
    cursor: not-allowed;
}

.disabled\:opacity-80:disabled {
    opacity: 0.8;
}

@media (min-width: 1024px) {
    .lg\:block {
        display: block;
    }

    .lg\:w-1\/2 {
        width: 50%;
    }

    .lg\:flex-row {
        flex-direction: row;
    }

    .lg\:justify-start {
        justify-content: flex-start;
    }

    .lg\:gap-3 {
        gap: 0.75rem;
    }

    .lg\:gap-4 {
        gap: 1rem;
    }

    .lg\:gap-6 {
        gap: 1.5rem;
    }

    .lg\:px-0 {
        padding-left: 0;
        padding-right: 0;
    }

    .lg\:text-left {
        text-align: left;
    }

    .lg\:text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem;
    }

    .lg\:text-5xl {
        font-size: 3rem;
        line-height: 1;
    }

    .lg\:text-sm {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .lg\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}


/* Dropdown Css */

.border-box-s {
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    vertical-align: initial;
}


.coin-block {
    align-items: center;
    justify-content: center;
    display: flex;
    height: inherit;
}

.w-100 {
    width: 100%;
}

.coins-list {
    position: absolute;
    background-color: white;
    z-index: 99;
    left: 20px;
    right: 20px;
    border: 1px solid #161617;
    border-radius: 10px;
}

.d-none{
    display: none;
}

.d-block{
    display: block;
}

div.mm-dropdown {
    cursor: pointer;
    width: 100%;
    border-radius: 3px;
    font-weight: 500;
}

div.mm-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 0;
}

.align-item-center {
    align-items: center;
}

div.mm-dropdown ul li,
div.mm-dropdown div.textfirst {
    padding: 0;
    color: #333;
    padding: 5px 15px;
}

div.mm-dropdown ul li:last-child {
    border-bottom: 0;
}

div.mm-dropdown ul li {
    cursor: pointer;
}

div.mm-dropdown ul li img {
    width: 20px;
    height: 20px;
}
.h-50 {
    height: 50px;
}



.progress-bar {
    height: 20px;
    background-color: #007bff;
    transition: width 0.3s ease;
  }

  .bold-red {
    color: red;
    font-weight: bold;
  }

  .tag-button {
    cursor: pointer; /* Pointer cursor on hover */
  }
